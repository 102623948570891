<template>
    <div>
        <p>Redirecting...Please wait.</p>
    </div>
</template>

<script>
import API from './../../utils/API'
import { mapActions } from 'vuex'
export default {
    data(){
        return {

        }
    },
    methods: {
        ...mapActions(['providerHandler']),
    },
    async mounted(){
        if(this.$route.path.includes('facebook')){//handler for facebook function
            API.get(`/auth/facebook/callback?access_token=${this.$route.query.access_token}`)
                .then(resAuth => {
                    this.providerHandler(resAuth.data)
                })
                .catch(err => {
                    console.log('err : ', err.response.data)
                })
            
        }
    }
}
</script>